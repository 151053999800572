/* eslint-disable @typescript-eslint/naming-convention */
export const apiPortMap: Record<number, string> = {
  // dev main
  0: 'http://34.213.175.248:3000/',
  1: 'http://34.213.175.248:3001/',
  2: 'http://34.213.175.248:3002/',
  // dev 1
  3: 'http://35.92.17.131:3003/',
  4: 'http://35.92.17.131:3004/',
  5: 'http://35.92.17.131:3005/',
  // dev 2
  6: 'http://54.187.5.42:3006/',
  7: 'http://54.187.5.42:3007/',
  8: 'http://54.187.5.42:3008/',
};
