import { initialConfig, optionsConfig } from 'ngx-mask';
import { NgxMaskApplierService } from 'ngx-mask/lib/ngx-mask-applier.service';

export const ngxMaskConfig: optionsConfig = {
  thousandSeparator: ',',
  decimalMarker: '.',
  allowNegativeNumbers: true,
  specialCharacters: initialConfig.specialCharacters.filter(v => v !== '-'),
  // eslint-disable-next-line @typescript-eslint/naming-convention
  patterns: { ...initialConfig.patterns, N: { pattern: /[-0-9]/ } },
  outputTransformFn: function (value: string | number | null | undefined) {
    const numericValue = +(value ?? 0);
    const isSeparator = (this as NgxMaskApplierService).maskExpression.startsWith('separator');
    if (isSeparator && !Number.isNaN(numericValue)) {
      return numericValue;
    }
    return value;
  },
  // covers cases with emitEvent false
  inputTransformFn: function (value: any) {
    if (!(this as NgxMaskApplierService).maskExpression.startsWith('separator')) {
      return value;
    }
    const numericValue = +(value ?? 0);
    if (Number.isNaN(numericValue) || value.toString().endsWith('.') || value === '' || value === '-0') {
      return value as string | number;
    }
    return numericValue;
  },
};
